/* @tailwind base; */
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
}
@media screen and (max-width: 768px) {
  .ant-table-cell {
    padding: 8px 8px;
  }
  .ant-table-thead th, .ant-table-tbody td {
    white-space: nowrap; 
  }
}
@media screen and (max-width: 768px) {
  .w-28 {
    width: 100%; 
  }
}
@media screen and (max-width: 768px) {
  .contact {
    display: flex;
    flex-wrap: wrap;
  }
  .gutter-row {
    flex: 0 0 100%; 
  }
}


@media screen and (max-width: 768px) {
  .flex-container {
    flex-direction: column;
  }
  .flex-item {
    width: 100%; 
  }
  .button-container {
    margin-top: 20px; 
    margin-left: 0; 
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sun-editor {
  min-width: 0px;
}

.sun-editor-text {
  min-width: 1200px;
  min-height: 80px;
}

.active-menu {
  border-bottom: 2px solid #61dafb;
}

.active-menu {
  color: #61dafb;
}

.my-popup-class {
  width: 500px;
  height: 280px; 
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.search-input {
  flex: 1; 
}


.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px; 
}

.search-input {
  width: 100%;
}

.menu-button {
  font-family: 'Noto Sans Thai", sans-serif';
}
