.ant-btn {
  min-width: 120px;
}

.ant-btn-lg {
  font-size: inherit;
  line-height: inherit;
}

.ant-btn.primary-outline {
  border: 1px solid #FF7F07;
  color: #FF7F07;
  background-color: #FFF;
}

.menu-dropdown {
  min-width: 40px ;
  background: white!important;
  border: none;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* font-family: "Noto Sans Thai",  sans-serif;
    font-size: 48px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
  background-color: #FFFAF5;
}




/* .nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody .ant-table-row {
    margin-top: 20px;
    margin-bottom: 20px;

} */


/* Antd Custom */
/* .ant-btn {
  line-height: 0;
}

.ant-btn-primary {
  background: #247cb3;
} */

.ant-layout-sider-trigger {
  background: #414141;
}

.ant-layout-sider {
  /* background-image: linear-gradient(to right, rgb(14, 15, 29), rgb(14, 15, 29), rgb(42,45,66)) !important; */
  background-image: radial-gradient(circle at 100%, rgb(55, 58, 80), rgb(14, 15, 29) 75%) !important;
  border-top-right-radius: 30px !important;
}



.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ant-radio-wrapper {
  margin: 14px 8px 0 0;
}

.ant-menu-item {
  /* padding-bottom: 20px !important; */
  margin-bottom: 15px !important;
  color: #98A2B3 !important;
}

.ant-menu-item-selected {
  background-color: #EB7F13 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  height: auto !important;
  color: #ffffff !important;
}

/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #247cb3;
}

.ant-menu-item-selected,
.ant-menu-item-selected>a,
.ant-menu-item-selected>a:hover {
    color: #ffffff;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
    border-right: 3px solid #FF9800;
}

*/
.search_inline {
  float: left;
}

.search_inline .ant-form-item-label {
  position: absolute;
  top: -25px;
  z-index: 1;
  line-height: 2;
}

.add_modal .ant-form-item-label {
  line-height: 22.9999px;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-header {
  border-bottom: 1px solid #d9d9d9;
  border-radius: 10px 10px 0 0;
}

.ant-modal-footer {
  border-top: none;
  padding: 16px 35px;
}

.ant-modal-title {
  font-size: 20px;
}

.ant-form-item-required:before {
  content: " ";
}

.ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  line-height: 1;
  content: "*" !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  content: " ";
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  line-height: 1;
  content: "*" !important;
}

.ant-modal-body {
  padding: 20px 35px;
  padding-bottom: 0;
}

.ant-radio-button-wrapper {
  line-height: 24px;
}

.ant-input-suffix {
  padding-right: 0px !important;
}

/* .ant-input {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #d9d9d9;
}

.ant-input:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #006eff;
}

.ant-select-selection {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #d9d9d9;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #006eff;
}

.ant-select-open .ant-select-selection {
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #006eff;
}

.has-error .ant-input,
.has-error .ant-input:hover {
    border-bottom: #f5222d 1px solid;
}

.has-error .ant-select-selection,
.has-error .ant-select-selection:hover {
    border-bottom: #f5222d 1px solid;
}

*/
.ant-table-placeholder {
  background: #fbfbfb;
}

.ant-form-inline .ant-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ant-menu-item>a {
  font-weight: 600;
}

.ant-layout,
.ant-layout-footer {
  background: #f2f3f8;
}

.ant-table-thead>tr>th.ant-table-column-sort {
  background: #f5f5f5 !important;
}

.ant-table-tbody>tr>td.ant-table-column-sort {
  background: #f5f5f5;
}

/* .ant-table table {
    min-width: 1500px;
} */

/* Antd Custom */

.layout_sid {
  flex: 1;
  width: auto;
}

.menu_sidebar .ant-drawer-body {
  padding: 0;
  overflow-x: hidden;
}

.sidebar_ {
  background: rgb(255, 255, 255);
  position: fixed;
  overflow: auto;
  overflow-x: hidden;
  left: 18px;
  padding: 10px 0 40px 0;
  margin-top: 70px;
  border-radius: 10px;
  z-index: 200;
  height: 88vh;
}

.logo {
  height: 60px;
  font-size: 20px;
  font-weight: 500;
  padding-left: 24px;
  padding-top: 10px;
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.layout {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
}

.site_content {
  flex: 1;
}

.logo_name {
  padding-left: 24px;
  color: #247cb3;
  font-weight: 600;
  font-size: 16px;
}

.content {
  margin-top: 20px;
  padding: 20px;
  padding-top: 10px;
  background: white;
  border-radius: 10px;
}

.dashboard_content {
  margin: 0 45px;
  overflow: hidden;
  position: relative;
}

.tag_create {
  height: 30px;
  line-height: 30px;
  align-items: center;
  justify-content: space-between;
  display: inline-flex;
  margin: 10px 0px;
  margin-right: 8px;
}

.inbox_table {
  display: block;
  overflow-x: auto;
}

.inbox_table .ant-table-thead>tr>th {
  background: #ffffff;
}

.inbox_table_sub .ant-table-thead>tr>th {
  background: #fbfbfb;
}

.inbox_table .ant-table-tbody>tr>td {
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
}

.create_form {
  padding: 30px;
  max-width: 500px;
}

.inbox_row_white {
  background: #ffffff;
}

.inbox_row_gray {
  background: #f0f2f5;
}

.search {
  display: flex;
}

.search_line {
  float: left;
  margin-right: 20px;
}

.search_button {
  float: left;
}

.story_header {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  padding: 0 45px;
  padding-left: 20px;
  z-index: 100;
}

.upload_cover .ant-upload-select-picture-card {
  width: 150px;
  height: 200px;
}

.text_title {
  font-size: 25px;
}

.text_ {
  font-size: 18px;
  line-height: 1.66666667;
  border: none;
  border-bottom: none;
  font-weight: 300;
}

.text_:focus {
  outline: none;
  box-shadow: none;
  border-bottom: none;
}

.edit_toolbar {
  line-height: 48px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 0 0 #ededf1;
  box-shadow: 0 1px 0 0 #ededf1;
  border-radius: 10px 10px 0 0;
}

.edit_body {
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  overflow: auto;
  top: 100px;
  bottom: 0;
  padding-top: 30px;
  border-radius: 10px 10px 0 0;
}

.edit_content {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.button_readtoo {
  border: none;
  text-decoration: none;
  width: auto;
  margin: 0px 10px;
  /* font-size: 16px; */
  font-weight: normal;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 20px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  /* color: #fff; */
  padding: 0 25px;
  display: inline-block;
  text-transform: uppercase;
}

/* .button_readtoo:focus {
    color: #ffffff;
} */

.create_story_menu {
  list-style-type: none;
  padding-inline-start: 0;
  margin-bottom: 0;
}

.create_story_menu li {
  cursor: pointer;
  padding: 5px 0;
  margin: 5px 0;
}

.create_story_menu li:hover {
  color: #59bae4;
}

.button_readtoo:hover {
  background: #59bae4;
  color: #fff;
  /* transform: translateY(-7px); */
}

.modal_create_link {
  height: 200px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
}

.modal_create_link:hover {
  background: #59bae4;
  transition: all 0.3s;
}

.modal_create_link:hover span {
  color: #ffffff;
}

.create_svg {
  color: #878787;
}

.modal_create_link:hover .create_svg {
  color: #ffffff;
}

.modal_create_link span {
  color: #878787;
  margin: 8px;
  display: block;
  font-size: 20px;
}

.line_center {
  width: 1px;
  height: 200px;
  border: 1px solid #878787;
}

.trigger {
  width: 72px;
}

/* Display */

.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.flex_ {
  display: flex;
}

.justify_space_between {
  justify-content: space-between;
}

.justify_flex_start {
  justify-content: flex-start;
}

.align_items {
  align-items: center;
}

.align_center {
  align-items: center;
}

.justify_center {
  justify-content: center;
}

.align_end {
  align-items: flex-end;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.login-form {
  width: 50%;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.login100-pic {
  width: 384px;
  font-size: 30px;
  font-weight: 600;
  color: #110b65;
}

.login100-pic p {
  margin-top: 10px;
}

.login100-pic img {
  max-width: 100%;
}

/* .container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: url("../images/bg-8.jpg") fixed center;
  background-size: cover;
} */

.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding: 40px;
}

.login100-form-btn {
  border: none;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  border-radius: 25px;
  background: #00aff0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:focus {
  background: #00aff0;
  color: #ffffff;
  border: none;
}

.login100-form-btn:hover {
  background: #110b65;
  color: #ffffff;
  border: none;
}

.login100-form-title {
  font-size: 24px;
  width: 100%;
  display: flex;
  padding-bottom: 54px;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: 100;
}

.login100-pic {
  padding: 20px;
}

/*------------------------------------------------------------------
[  ]*/
/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 990px) {
  .dashboard_content {
    margin: 50px 16px 20px;
  }

  .wrap-login100 {
    flex-wrap: wrap;
  }

  .login100-pic {
    display: none;
  }

  .login100-pic {
    width: 35%;
  }

  .login-form {
    width: 100%;
  }

  .story_header {
    width: 100%;
    padding: 0 16px;
    background: #fff;
  }
}

@media (max-width: 768px) {
  .wrap-login100 {
    flex-wrap: wrap;
  }

  .login100-pic {
    display: none;
  }

  .login-form {
    width: 100%;
  }
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
  /* position: absolute; */
  border-radius: 1rem;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  margin-right: 0;
}

/* Scoll Bar  */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(223, 223, 223);
}

.ant-form-item-label label {
  font-weight: 500;
}

.custom-checkbox>label {
  margin-right: 0px !important;
}

.custom-checkbox>label>span:last-child {
  width: 100%;
}

.ant-image-preview-img-wrapper {
  display: grid;
  justify-items: center;
}

.ant-image-preview-img-wrapper>.ant-image-preview-img {
  max-height: 80vh;
}

.two-rows-label .ant-form-item-label {
  padding: 4px 0 !important;
}

.two-rows-label .ant-form-item-label label {
  white-space: normal;
  margin-right: 12px;
  display: inline-block;
  text-align: center;
  line-height: 12px;
  width: 300px;
}

.two-rows-label .ant-form-item-label label:after {
  content: none !important;
}

.two-rows-label .ant-form-item-label label.ant-form-item-required:before {
  position: absolute;
  left: -7px;
}

.two-rows-label .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: none !important;
}

.preview-moblie .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.preview-moblie .ant-modal-close-x {
  color: #fff !important;
  font-size: 24px;
}

.preview-moblie-body {
  padding: 8px;
  margin: 24px 4px;
  background: #404040;
  border: 2.86331px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 13px;
}

.preview-moblie-screen {
  padding: 4px;
  border-radius: 13px;
  background: #fff;
  height: 55vh;
}

.preview-moblie-header {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  display: flex;
  justify-content: center;
  color: #000000;
  padding: 16px;
  box-shadow: 0px 4px 20px rgba(32, 32, 32, 0.06);
}

.preview-moblie-box {
  height: 45vh !important;
  overflow-y: scroll !important;
  margin-right: -22px;
  padding-right: 8px;
  width: 230px;
}

.ellipsis-two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 500;
  font-size: 14px;
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 500;
  font-size: 14px;
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 38px;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-title .anticon {
  max-width: 18px;
  width: 18px;
}

.ant-form-item-explain-error {
  margin-bottom: 16px;
}

/* .ant-btn {
  line-height: 0;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  place-content: center;
} */

/* .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 16px;
} */

.expand-custom-header {
  margin: -16px -16px 16px -16px !important;
  padding: 14px 4rem 14px 4rem;
  background: #f3f3f3;
}

.expand-custom-detail {
  margin: -16px !important;
  padding: 14px 4rem 14px 4rem;
}


/* **************DashBoard Page********* */
.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table {
  border-collapse: collapse;
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead th {
  padding: 8px;
  color: #667085;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 18px;
  font-weight: normal !important;
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead tr {
  border: 10px solid transparent;
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody tr {
  border: 10px solid transparent;
  font-weight: bold;
  color: #1C2538
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody td:not(first-child) {
  background-color: #F8F8F9;
  padding-top: 5px;
  padding-bottom: 10px;
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody td:not(:last-child):after {
  content: '';
  height: 50%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 25%;
  background-color: #e3e5e9;
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody td:first-child {
  padding-left: 10px;
  border-radius: 30px 0 0 30px;
  background-color: #F8F8F9
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody td:last-child {
  border-radius: 0 30px 30px 0;
  background-color: #F8F8F9
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead th:first-child {
  padding-left: 10px;
  border-radius: 30px 0 0 30px;
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead th:last-child {
  padding-left: 10px;
  border-radius: 0 30px 30px 0;
}

.nonVoiceReportTable .ant-spin-container .ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody td .ant-progress-text {
  color: rgb(0, 0, 0)
}


/* **************tab reportPage********* */
.report-tabs .ant-tabs-nav .ant-tabs-tab-active {
  color: blue;
  /* Change the text color of the active tab */
}

.report-tabs .ant-tabs-nav .ant-tabs-tab:hover {
  color: #1890FF;
  /* Change the text color of the tabs on hover */
}

.report-tabs .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890FF;
  /* Change the text color of the active tab's button */
  font-weight: 700;
}

.report-tabs .ant-tabs-ink-bar {
  background-color: #1890FF; /* Change the color of the ink bar */
}

.ant-pagination-item.ant-pagination-item-active{
  font-weight: 600;
  background-color: #FF7F07;
  border-color: #FF7F07;
  color: #ffffff;
}
.ant-pagination-item.ant-pagination-item-active a{
  color: #ffffff;
}